import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationMenu, NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import { TranslateService } from '@ngx-translate/core';
import { ViewConfigurationService } from '@dpdhl-iot/shared';
import { TranslocoService } from '@jsverse/transloco';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Subscription } from 'rxjs';
import axios, { AxiosError, AxiosResponse } from 'axios';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  applicationTitle: string[] = ['Inventory', 'Tracking'];
  navigationMenu: NavigationMenu[] = [];
  title = 'Inventory Tracking';
  languages = ['en', 'fr'];
  menuToggle = false;

  private subs: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private toastNotificationService: NotificationDataService,
    private viewConfigurationService: ViewConfigurationService,
    private translocoService: TranslocoService,
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.interceptAxios();
    this.CreateMenuItems();
  }

  public ngOnInit(): void {
    const browserLang = this.translateService.getBrowserLang() ?? '';
    if (this.languages.indexOf(browserLang) > -1) {
      this.translateService.setDefaultLang(browserLang);
    } else {
      this.translateService.setDefaultLang('en');
    }

    this.subs.push(
      this.viewConfigurationService.currentViewConfiguration$.subscribe((viewConfiguration) => {
        if (viewConfiguration) {
          this.translateService.setDefaultLang(viewConfiguration.language);
          this.translateService.currentLang = viewConfiguration.language;
          this.translateService.defaultLang = viewConfiguration.language;
          this.translocoService.setActiveLang(viewConfiguration.language);
          this.CreateMenuItems();
        }
      }),
    );
  }

  toggleMenu(event: boolean): void {
    this.menuToggle = event;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
  }

  protected interceptAxios(): void {
    axios.interceptors.response.use(
      async (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        this.toastNotificationService.addNotification({
          text: error.message,
          type: NotificationType.ERROR,
        });
        await Promise.reject(error);
      },
    );
  }

  private CreateMenuItems(): void {
    this.translateService
      .get([
        'menu.dashboard',
        'menu.facility',
        'menu.alert',
        'menu.alertTemplates',
        'menu.notifications',
        'menu.deviceManagement',
        'menu.usageOverview',
      ])
      .subscribe((res) => {
        this.navigationMenu = [
          {
            label: res['menu.dashboard'],
            path: '',
          },
          {
            label: res['menu.facility'],
            path: 'facilities',
          },
          {
            label: res['menu.alert'],
            path: 'alerts',
          },
          {
            label: res['menu.alertTemplates'],
            path: 'alert-rule-groups',
          },
          {
            label: res['menu.notifications'],
            path: 'notifications',
          },
          {
            label: res['menu.deviceManagement'],
            path: 'device-management',
          },
          {
            label: res['menu.usageOverview'],
            path: 'usage-overview',
          },
        ];
      });
  }
}
